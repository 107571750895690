import logo from './logo.svg';
import './App.scss';
import React, { useEffect, useState } from 'react';
import { Routes, Route, useLocation } from "react-router-dom";
import Home from './pages/home';
import Qustions from './pages/qustions';
import Results from './pages/results';
import BG from '../src/assets/images/bg.png';
import dinoLogo from './assets/images/created_by_dino_text_white.png';
import { AnimatePresence } from "framer-motion";
function App() {
  const location = useLocation();
  useEffect(() => {



  }, []);



  return (
    <div className="App">
      <Routes >
        <Route path="/" element={<Home />} />
        <Route path="/qustions" element={<Qustions />} />
        <Route path="/results" element={<Results />} />
      </Routes>
    </div>

  );
}

export default App;
