
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import Header from '../components/Header';
import loaderGif from '../assets/images/loader-spin.gif';
import dinoLogo from '../assets/images/created_by_dino_text_white.png';


function Question() {

    const navigate = useNavigate();
    const location = useLocation();
    const [questions, setQuestions] = useState([
        {
            question: "במהלך 24 השעות האחרונות עד כמה היה לך קשה להירדם עקב דלקת העור האטופית?",
            scores: [0, 1, 2, 3, 4, 5],
            min_text: 'ללא קושי',
            max_text: 'קשה ביותר',
            cat: 1,
            number: 0,
            answer: ''
        },
        {
            question: "במהלך 24 השעות האחרונות עד כמה דלקת העור האטופית השפיעה על השינה שלך?",
            scores: [0, 1, 2, 3, 4, 5],
            min_text: 'בכלל לא ',
            max_text: 'במידה קיצונית ',
            cat: 1,
            answer: '',
            number: 1,
        },
        {
            question: "במהלך 24 השעות האחרונות עד כמה הטרידה אותך ההתעוררות במהלך הלילה עקב דלקת העור האטופית?",
            scores: [0, 1, 2, 3, 4, 5],
            min_text: 'לא הטרידה ',
            max_text: 'הטרידה ביותר ',
            cat: 1,
            answer: '',
            number: 2,
        },

        {
            question: "	במהלך 7 הימים האחרונים עד כמה דלקת העור האטופית שלך הגבילה את פעילויותיך במטלות הבית (למשל, שטיפת כלים, טאטוא רצפה, כביסה)?",
            scores: [0, 1, 2, 3, 4, 5],
            min_text: ' לא הגבילה',
            max_text: '	הגבילה ביותר  ',
            cat: 2,
            answer: '',
            number: 3,
        },

        {
            question: "במהלך 7 הימים האחרונים עד כמה דלקת העור האטופית שלך הגבילה את הפעילויות הגופניות שלך (למשל, הליכה, אימוני כושר)?",
            scores: [0, 1, 2, 3, 4, 5],
            min_text: ' לא הגבילה',
            max_text: '	הגבילה ביותר  ',
            cat: 2,
            answer: '',
            number: 4,
        },

        {
            question: "במהלך 7 הימים האחרונים עד כמה דלקת העור האטופית שלך הגבילה את הפעילויות החברתיות שלך?",
            scores: [0, 1, 2, 3, 4, 5],
            min_text: ' לא הגבילה',
            max_text: '	הגבילה ביותר  ',
            cat: 2,
            answer: '',
            number: 5,
        },

        {
            question: "במהלך 7 הימים האחרונים, עד כמה היה לך קשה להתרכז עקב דלקת העור האטופית?",
            scores: [0, 1, 2, 3, 4, 5],
            min_text: '  ללא קושי',
            max_text: '	 קשה ביותר  ',
            cat: 2,
            answer: '',
            number: 6,
        },

        {
            question: "במהלך 7 הימים האחרונים, עד כמה הרגשת נבוך/ה  עקב דלקת העור האטופית?",
            scores: [0, 1, 2, 3, 4, 5],
            min_text: ' לא נבוך/ה',
            max_text: '	  נבוך/ה ביותר ',
            cat: 3,
            answer: '',
            number: 7,
        },

        {
            question: "במהלך 7 הימים האחרונים, עד כמה הרגשת מבויש/ת עקב דלקת העור האטופית?",
            scores: [0, 1, 2, 3, 4, 5],
            min_text: ' לא מבויש/ת',
            max_text: '	 מבויש/ת ביותר',
            cat: 3,
            answer: '',
            number: 8,
        },

        {
            question: "במהלך 7 הימים האחרונים, עד כמה הרגשת עצוב/ה עקב דלקת העור האטופית?",
            scores: [0, 1, 2, 3, 4, 5],
            min_text: 'לא עצוב/ה',
            max_text: '	עצוב/ה ביותר',
            cat: 3,
            answer: '',
            number: 9,
        },
    ]);

    const [question, setQuestion] = useState(0);
    const [loading, setLoading] = useState(false);
    const [show, setshow] = useState(false);

    const chooseItem = (item) => {

        let num = Number(question) + 1

        // window.dataLayer.push({
        //     event: 'Dynamic Question Num. ' + num + '/' + questions.length,
        //     eventProps: {
        //         label: 'Answer',
        //         value: item
        //     }
        // });

        window.gtag('event','Dynamic Question Num. ' + num + '/' + questions.length, {
            'parameter1': item, // Include your custom parameter here
           
            });

        setLoading(true)

        let temp_state = [...questions];

        for (let i = 0; i < temp_state.length; i++) {
            if (temp_state[i].number == question) {
                temp_state[i].answer = item
            }
        }

        if (Number(question) + 1 >= questions.length) {
            navigate('/results', { state: { questions: questions } })
            return;
        }

        setTimeout(() => {
            setLoading(false)
            setQuestions(temp_state);
            localStorage.setItem("qus", question);
            setQuestion(Number(question) + 1);
        }, 400);


    }
    useEffect(() => {

        if (localStorage.getItem("qus")) {
            setQuestion(localStorage.getItem("qus"))
        }

        setTimeout(() => {
            setshow('show')
        }, 0);

    }, []);

    const getMobileOperatingSystem = () => {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;

        // Windows Phone must come first because its UA also contains "Android"
        if (/windows phone/i.test(userAgent)) {
            return "";
        }

        if (/android/i.test(userAgent)) {
            return "";
        }

        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return "ios-device";
        }

        return "";
    }

    return (
        <>
            <div className={'wrap-home fade-in' + getMobileOperatingSystem()+' '+show}>
                <div className='wrap-home-top'>
                    <Header></Header>
                    <div className='container container-small'>
                        <div className='qus-title'>
                            <p>
                                דלקת עור אטופית:
                                <span>
                                    {' '}
                                    סולם השפעה
                                </span>
                            </p>
                        </div>
                        <div className='qus-sub-title'>
                            <p>
                                בכל שאלה, סמנו את התשובה המתארת באופן הטוב ביותר את חוויותיך עם דלקת העור האטופית,

                                <strong>
                                    {' '}
                                    אין תשובות נכונות או לא נכונות
                                </strong>
                            </p>
                        </div>

                        <div className='cards'>
                            <div className='item' >
                                {loading && <div className='loader'><img src={loaderGif} /></div>}
                                <div className='count-cards'>
                                    {Number(question) + 1}/{questions.length}
                                </div>
                                <div className='qus' >
                                    {questions[question].question}
                                </div>

                                <div className='answers'>
                                    {questions[question].scores.map((item, i) => {
                                        return (
                                            <div className='wrap-ans' key={item}>
                                                <div
                                                    className={
                                                        "answer "
                                                        +
                                                        (questions[question].answer == item ? "active " : " ")
                                                    }
                                                    onClick={() => chooseItem(item)} >
                                                    {item}
                                                </div>
                                                {item == 0 && <div className='rate-label'> {questions[question].min_text} </div>}
                                                {item == 5 && <div className='rate-label'> {questions[question].max_text}  </div>}
                                            </div>
                                        )
                                    })}
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
                <a href="https://devdino.com?utm_source=mako_SkinInflammationQuiz" className='dino-link' target="_blank">
                    <img src={dinoLogo} />
                </a>
            </div>


        </>
    );
}

export default Question;
