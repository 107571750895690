
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import Header from '../components/Header';
import Min from '../assets/images/minimal.svg';
import Easy from '../assets/images/easy.svg';
import High from '../assets/images/high.svg';
import Medium from '../assets/images/medium.svg';
import NoEffect from '../assets/images/no-effect.svg';
import dinoLogo from '../assets/images/created_by_dino_text_white.png';

function Results() {

    const navigate = useNavigate();
    const location = useLocation();
    const [scores, setscores] = useState(0);
    const [scorescat2, setscorescat2] = useState(0);
    const [scorescat3, setscorescat3] = useState(0);
    const [status, setstatus] = useState('no-effect');
    const [statuscat2, setstatuscat2] = useState('no-effect');
    const [statuscat3, setstatuscat3] = useState('no-effect');
    const { state } = useLocation();
    const { questions } = state;
    const MAKO_EVENT_URL = "https://mobileapp.mako.co.il/metricsCall.html?vcmId=[VCMID]&channelId=[CHANNEL_ID]&contentType=[CONTENT_TYPE]&platform=[PLATFORM]&ref=[REF]&mako_ExternalProjectID=[EXT_PROJECT_ID]";
    const _lastReferrer = null;

    useEffect(() => {


        SendMakoEvent("results", "CommercialProjects", "content", null, _lastReferrer, "EX_medquiz")
        localStorage.setItem("qus", 0);
        checkScore();


    }, []);

    const SendMakoEvent = (vcmId, channelId, contentType, platform, referrer, extProjectId) => {


        // Get the platform if not mentioned.
        if (!platform) {
            var width = window.innerWidth;
            platform = (width < 768) ? "mobile" : "web";
        }

        // Get the referrer if not mentioned.
        if (!referrer) {
            referrer = document.referrer;
        }

        // Create the URL.
        var iframeUrl = MAKO_EVENT_URL.
            replace("[VCMID]", encodeURIComponent(vcmId)).
            replace("[CHANNEL_ID]", encodeURIComponent(channelId)).
            replace("[CONTENT_TYPE]", encodeURIComponent(contentType)).
            replace("[PLATFORM]", encodeURIComponent(platform)).
            replace("[REF]", encodeURIComponent(referrer)).
            replace("[EXT_PROJECT_ID]", encodeURIComponent(extProjectId));

        // alert(iframeUrl)
        // Add to the document.
        AddMakoIframeEventToUrl(iframeUrl);
    }

    function AddMakoIframeEventToUrl(pixelUrl) {
        /// <summary>Add conversion pixel to the page, using an invisible iframe.</summary>
        /// <param name="pixelUrl" type="String">The URL of the pixel.</param>

        var iframe = document.createElement('iframe');
        iframe.style.width = '0px';
        iframe.style.height = '0px';
        iframe.style.visibility = 'hidden';
        iframe.style.position = 'absolute';
        iframe.style.top = '0';
        iframe.style.left = '0';
        iframe.style.zIndex = '-100';
        document.body.appendChild(iframe);
        iframe.src = pixelUrl; // put URL to tracking code here.
    };

    const checkScore = () => {

        let score = 0;
        let scorecat2 = 0;
        let scorecat3 = 0;
        for (let i = 0; i < questions.length; i++) {

            if (questions[i].cat == 1) {
                score += Number(questions[i].answer)
            }

            if (questions[i].cat == 2) {
                scorecat2 += Number(questions[i].answer)
            }

            if (questions[i].cat == 3) {
                scorecat3 += Number(questions[i].answer)
            }

        }


        setscores(score);
        setscorescat2(scorecat2);
        setscorescat3(scorecat3);

        setstatus(checkScoreStatus(score));
        setstatuscat2(checkScoreStatus(scorecat2));
        setstatuscat3(checkScoreStatus(scorecat3));

        let send = "איכות שינה " + statusToLabel(checkScoreStatus(score)) + ',פעילויות יום יומיות  ' + statusToLabel(checkScoreStatus(scorecat2)) + ', מצב רגשי' + statusToLabel(checkScoreStatus(scorecat3));


        // window.dataLayer.push({
        //     event: 'Results',
        //     eventProps: {
        //         label: 'Results',
        //         value: send
        //     }
        // });

        window.gtag('event', 'Results', {
            'parameter1': send,

        });
    }

    const statusToLabel = (stat) => {

        if (stat == 'no-effect') {
            return ' אין השפעה'
        }

        if (stat == 'minimal') {
            return ' השפעה מינימלית '
        }

        if (stat == 'easy') {
            return ' השפעה קלה '
        }

        if (stat == 'medium') {
            return ' השפעה בינונית '
        }

        if (stat == 'high') {
            return ' השפעה גבוהה '
        }
    }

    const checkScoreStatus = (score) => {

        if (score == 0) {

            return 'no-effect';
        }

        if (score >= 1 && score <= 2) {

            return 'minimal';
        }

        if (score >= 3 && score <= 5) {

            return 'easy';
        }

        if (score >= 6 && score <= 10) {

            return 'medium';
        }

        if (score >= 11) {

            return 'high';
        }
    }

    const getStatusImg = (stat) => {
        if (stat == 'no-effect') {
            return NoEffect;
        }
        if (stat == 'easy') {
            return Easy;
        }
        if (stat == 'minimal') {
            return Min;
        }
        if (stat == 'medium') {
            return Medium;
        }
        if (stat == 'high') {
            return High;
        }
    }

    const getMobileOperatingSystem = () => {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;

        // Windows Phone must come first because its UA also contains "Android"
        if (/windows phone/i.test(userAgent)) {
            return "";
        }

        if (/android/i.test(userAgent)) {
            return "";
        }

        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return "ios-device";
        }

        return "";
    }

    const getSleep = () => {

        return (

            <div className={'result-item ' + status}>
                <div className='result-item-label'>
                    איכות שינה
                </div>

                <div className='result-item-image'>
                    <img src={getStatusImg(status)} />
                </div>
            </div>

        )
    }

    const getDaily = () => {

        return (

            <div className={'result-item ' + statuscat2}>
                <div className='result-item-label'>
                    פעילות
                    <br />
                    יום-יומית
                </div>

                <div className='result-item-image'>

                    <img src={getStatusImg(statuscat2)} />
                </div>
            </div>

        )
    }

    const getFeel = () => {

        return (

            <div className={'result-item ' + statuscat3}>
                <div className='result-item-label'>
                    מצב רגשי
                </div>

                <div className='result-item-image'>
                    <img src={getStatusImg(statuscat3)} />
                </div>
            </div>

        )
    }

    const goto = () => {
        // window.dataLayer.push({
        //     event: 'More info',
        // });
        window.gtag('event', 'More Info', {

        });
        window.open('https://www.mako.co.il/nPreview/vcmid=0742608d39d17810VgnVCM100000700a10acRCRD', '_blank');

    }

    return (
        <>
            <div className={'wrap-home ' + getMobileOperatingSystem()}>

                <div className='results-top'>
                    <Header></Header>

                    <div className='container container-small container-small-pad'>
                        <div className='qus-title qus-title-results'>
                            <p>
                                תוצאות השאלון שלך:
                            </p>
                        </div>
                        <div className='qus-title-discalimer'>
                            <p>
                                מידת ההשפעה של אאטופיק דרמטיטיס על:
                            </p>
                        </div>

                        <div className='results'>

                            {getSleep()}
                            {getDaily()}
                            {getFeel()}

                        </div>

                    </div>
                </div>


                <div className='wrap-btn wrap-btn-results'>
                    <div className='container container-small'>
                        <div className='qus-sub-title qus-sub-title-res'>
                            <p>
                                <strong>
                                    פנה/י אל רופא/ת העור שלך או מרפאות אטופיק
                                    <br />
                                    דרמטיטיס בבתי החולים ושאל/י לגבי טיפולים
                                    <br />
                                    חדשים באטופיק דרמטיטיס
                                </strong>
                            </p>
                        </div>
                    </div>

                    <div className='' style={{ margin: '14px 0' }}></div>

                    <a className='btn' onClick={() => goto()}>
                        למידע נוסף
                        {' >> '}
                    </a>
                    <a href="https://devdino.com?utm_source=mako_SkinInflammationQuiz" className='dino-link' target="_blank">
                        <img src={dinoLogo} />
                    </a>
                </div>

            </div>
        </>
    );
}

export default Results;
