
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import Header from '../components/Header';
import filter from '../assets/images/filter.svg';
import dinoLogo from '../assets/images/created_by_dino_text_white.png';
function Home() {

    const navigate = useNavigate();
    const location = useLocation();

    const MAKO_EVENT_URL = "https://mobileapp.mako.co.il/metricsCall.html?vcmId=[VCMID]&channelId=[CHANNEL_ID]&contentType=[CONTENT_TYPE]&platform=[PLATFORM]&ref=[REF]&mako_ExternalProjectID=[EXT_PROJECT_ID]";
    let _lastReferrer = null;

    useEffect(() => {

        SendMakoEvent("HP", "CommercialProjects", "HP", null, _lastReferrer, "EX_medquiz")
    }, []);

    const SendMakoEvent = (vcmId, channelId, contentType, platform, referrer, extProjectId) => {


        // Get the platform if not mentioned.
        if (!platform) {
            var width = window.innerWidth;
            platform = (width < 768) ? "mobile" : "web";
        }

        // Get the referrer if not mentioned.
        if (!referrer) {
            referrer = document.referrer;
        }

        // Create the URL.
        var iframeUrl = MAKO_EVENT_URL.
            replace("[VCMID]", encodeURIComponent(vcmId)).
            replace("[CHANNEL_ID]", encodeURIComponent(channelId)).
            replace("[CONTENT_TYPE]", encodeURIComponent(contentType)).
            replace("[PLATFORM]", encodeURIComponent(platform)).
            replace("[REF]", encodeURIComponent(referrer)).
            replace("[EXT_PROJECT_ID]", encodeURIComponent(extProjectId));

        AddMakoIframeEventToUrl(iframeUrl)
        // Add to the document.
        console.log(iframeUrl)
    }

    function AddMakoIframeEventToUrl(pixelUrl) {
        /// <summary>Add conversion pixel to the page, using an invisible iframe.</summary>
        /// <param name="pixelUrl" type="String">The URL of the pixel.</param>

        var iframe = document.createElement('iframe');
        iframe.style.width = '0px';
        iframe.style.height = '0px';
        iframe.style.visibility = 'hidden';
        iframe.style.position = 'absolute';
        iframe.style.top = '0';
        iframe.style.left = '0';
        iframe.style.zIndex = '-100';
        document.body.appendChild(iframe);
        iframe.src = pixelUrl; // put URL to tracking code here.
    };

    const start = () => {


        window.gtag('event', 'start', {

        });
        {/* window.dataLayer.push({
            event: 'Start'
        }); */}

        navigate('/qustions')
    }

    const getMobileOperatingSystem = () => {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;

        // Windows Phone must come first because its UA also contains "Android"
        if (/windows phone/i.test(userAgent)) {
            return "";
        }

        if (/android/i.test(userAgent)) {
            return "";
        }

        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return "ios-device";
        }

        return "";
    }

    return (
        <>

            <div className={'wrap-home ' + getMobileOperatingSystem()}>

                <div className='wrap-home-top'>
                    <Header></Header>
                    <div className='image-status'>
                        <img src={filter} />
                    </div>
                    <div className='container'>
                        <div className='big-title'>
                            <p>
                                שאלון 
                                <span className='english-text bold-en'> Score or </span>
                                <br className='show' style={{display:'block'}}></br>
                                דלקת עור
                                <br />
                                {' '}
                                אטופית:
                            </p>
                        </div>
                        <div className='big-title blue'>
                            <p>
                                סולם השפעה
                            </p>
                        </div>
                        <div className='content content-m'>
                            <p>
                            שאלון <span className='english-text'>score or</span>  פונה לאנשים המתמודדים עם דלקת
                                <br />
                                עור אטופית הידועה גם כאקזמה, ורוצים למדוד את
                                <br />
                                ההשפעה של המחלה על חייהם
                            </p>
                        </div>


                    </div>
                </div>

                <div className='wrap-disclamer-and-btn'>
                    <div className='wrap-btn'>
                        <a className='btn' onClick={start}>
                            התחילו
                        </a>
                    </div>
                    <div className='disclamer'>
                        <p>
                            *השאלון מבוסס על ADERM-IS ואינו מחליף יעוץ רפואי
                        </p>
                    </div>
                    <a href="https://devdino.com?utm_source=mako_SkinInflammationQuiz" className='dino-link dino-link-home' target="_blank">
                        <img src={dinoLogo} />
                    </a>
                </div>

            </div>
        </>
    );
}

export default Home;
