import React from 'react';
import Logo from '../assets/images/logo.svg';
import Med from '../assets/images/med.svg';

function Header() {

    const clickMed = () => {

        
        window.gtag('event', 'MED 12 Logo', {

        });

        window.open('https://www.mako.co.il/news-med12', '_blank');
    }

    const clickAbb = () => {
      

        window.gtag('event', 'abbvie Logo', {

        });
        window.open('https://www.mako.co.il/health-special/xtra_atopic_dermatitis', '_blank');
        
    }


    return (
        <div className='header'>
            <div className='med12' onClick={() => clickMed()}>
                <img src={Med} />
            </div>

            <div className='logo-quiz' onClick={() => clickAbb()}>
                <img src={Logo} />
            </div>
        </div>

    );
}

export default Header;
